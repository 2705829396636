import { SEO } from "components/common/SEO";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

import ActionRatio from "../components/actionRatio/ActionRatio";
import Layout from "../components/common/layout/layout";
import HomeMarkupSchema from "../components/common/SEO/HomeMarkupSchema";
import HeroHome from "../components/heroHome/HeroHome";
import SliderSegments from "../components/sliderSegments/SliderSegments";

const IndexPage = () => {
  const { allSanitySegment: segments } = useStaticQuery(graphql`
    query CardSegments {
      allSanitySegment {
        edges {
          node {
            has_ready
            slug {
              es_AR
              pt_BR
            }
            card {
              title {
                es_AR {
                  children {
                    text
                  }
                }
              }
              img {
                asset {
                  id
                  _id
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <HeroHome />
      <SliderSegments segments={segments} />
      <section className={"is-relative"}>
        <div className={"a-radar a-radar--left a-radar--home"} />
      </section>
      <section className="container-small">
        <p
          className={
            "a-paragraph is-text-center a-underline a-underline--spacing-bottom"
          }
        >
          Los servicios de <strong>C&R Seguridad</strong> van más allá del
          ámbito la seguridad, ofreciendo apoyo en toda la operación, como{" "}
          <strong>
            <Link to="/recepcion-y-mesa/"> recepción y mesa</Link>
          </strong>{" "}
          o{" "}
          <strong>
            <Link to="/supervision-presentismo/">
              supervisión y presentismo
            </Link>
          </strong>{" "}
          – con profesionales cualificados y expertos en el campo. La idea es
          que con nuestra ayuda, su empresa pueda tener un día a día seguro y
          tranquilo para desempeñarse con la mayor eficiencia en su área de
          actuación. Las palabras "excelencia, compromiso y calidad" son la
          columna vertebral de la misión, visión y valores de C&R Seguridad.
          Somos una empresa de seguridad que desde el principio se ha centrado
          en la valoración de las personas y la ética, y nos enorgullecemos de
          ser exitosos en brindar nuestro máximo esfuerzo a los clientes en los
          estados en los que operamos.
        </p>{" "}
        <p className={"a-paragraph is-text-center p-vertical-l"}>
          Al <strong>contratar una empresa de seguridad</strong>, el cliente no
          solo piensa en un factor.{" "}
          <i>Buscamos conocer primero las necesidades</i> para ofrecer un nivel
          de servicio que corresponda a las más altas expectativas.
        </p>
      </section>
      <ActionRatio />
    </Layout>
  );
};

export const Head = () => (
  <>
    <SEO
      title="Empresa de Seguridad Privada Argentina | C&R Seguridad"
      description="Somos una empresa de seguridad privada integral. Contamos con servicios de vigilancia, seguridad electronica, sistemas de cctv y mucho más. Consultanos."
      robots="index, follow"
    />
    <HomeMarkupSchema />
  </>
);

export default IndexPage;
