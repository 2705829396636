import "./companies.scss";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const Companies: React.FC<any> = (props: {
  is_backLight: boolean;
  color: string;
}) => {
  const carouselCompanies = useRef() as React.MutableRefObject<HTMLDivElement>;
  const intervalID = useRef(0);

  const { is_backLight, color } = props;
  const { t } = useTranslation("global");
  const { allFile } = useStaticQuery(graphql`
    query CompaniesQuery {
      allFile(filter: { sourceInstanceName: { eq: "companies" } }) {
        nodes {
          name
          childImageSharp {
            id
            gatsbyImageData(height: 50)
          }
          publicURL
        }
      }
    }
  `);

  function cleanNameFilePng(filename: string): string {
    return filename.split("-")[1];
  }

  const calculateFitClass = () => {
    if (
      !carouselCompanies ||
      !carouselCompanies.current ||
      !carouselCompanies.current.children ||
      carouselCompanies.current.children.length === 0
    ) {
      return;
    }
    // const brandElement = carouselCompanies.current.children[0] as HTMLElement;
    // // 96 is de $spacing-xl defined in scss and utility like gap
    // // const is_fit = (brandElement.offsetWidth + 96) * carouselCompanies.current.children.length < getWidthScreen();
    // const is_fit =
    //   carouselCompanies.current.offsetWidth ===
    //   carouselCompanies.current.scrollWidth;
    if (intervalID.current > 0) {
      clearInterval(intervalID.current);
      intervalID.current = 0;
    }
    carouselCompanies.current.classList.remove("c-brands--fit");
    if (intervalID.current === 0) {
      intervalID.current = setInterval(() => {
        next();
      }, 0) as unknown as number;
    }
  };
  const next = useCallback(() => {
    if (
      !carouselCompanies ||
      !carouselCompanies.current ||
      !carouselCompanies.current.children ||
      carouselCompanies.current.children.length === 0
    ) {
      return;
    }
    const firstBrand = carouselCompanies.current.children[0] as HTMLElement;
    const SecondBrand = carouselCompanies.current.children[1] as HTMLElement;
    carouselCompanies.current.style.transition = `10000ms linear all`;
    const nextBrand = SecondBrand.offsetLeft;
    carouselCompanies.current.style.transform = `translateX(-${nextBrand}px)`;
    const transition = () => {
      carouselCompanies.current.style.transition = "none";
      carouselCompanies.current.style.transform = "translateX(0)";
      carouselCompanies.current.appendChild(firstBrand);
      carouselCompanies.current.removeEventListener(
        "transitionend",
        transition
      );
    };
    carouselCompanies.current.addEventListener("transitionend", transition);
  }, []);

  useEffect(() => {
    calculateFitClass();
  }, []);

  return (
    <div className={"o-companies p-vertical-l"}>
      <div className="container">
        <h2
          className={
            is_backLight
              ? " a-title a-title--body a-underline a-underline--left"
              : " a-title a-title--body is-white a-underline a-underline--left"
          }
        >
          {t("companies.title")}{" "}
          <span className="is-orange"> C&R Seguridad </span>{" "}
        </h2>
      </div>
      <div className="cs-companies" ref={carouselCompanies}>
        {allFile.nodes.map((company, index) => {
          return (
            <GatsbyImage
              className={"m-company__icon"}
              title={`Logotipo de ${cleanNameFilePng(company.name)}`}
              imgClassName={`a-company__icon ${
                props.is_backLight ? "" : "a-company__icon--lighter"
              }`}
              key={index}
              alt={`Logotipo de ${cleanNameFilePng(company.name)}`}
              image={company.childImageSharp.gatsbyImageData}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Companies;
