import "./hero-home.scss";

import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Trans, useTranslation } from "react-i18next";

import Companies from "../companies/Companies";
import SliderServices from "../sliderServices/SliderServices";

const HeroHome: React.FC = () => {
  const { t } = useTranslation("global");
  const { allSanityService: services } = useStaticQuery(graphql`
    query CardServices {
      allSanityService(
        filter: { has_ready: { eq: true } }
        sort: { fields: position, order: ASC }
      ) {
        edges {
          node {
            has_ready
            slug {
              pt_BR
              es_AR
            }
            card {
              title {
                es_AR {
                  children {
                    text
                  }
                }
              }
              img {
                asset {
                  _id
                  gatsbyImageData
                  id
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div className={"o-hero-home"}>
      <div className="container">
        <div className={"grid-70-30 m-hero-home-content"}>
          <section
          // data-i18n="[html]content.body"
          // data-sal="slide-up"
          // data-sal-duration="800"
          // data-sal-easing="ease"
          >
            <h1 className={"a-title a-title--hero"}>
              <Trans t={t}>heroHome.title</Trans>
            </h1>
            <p className={"a-paragraph a-paragraph--hero is-c-light"}>
              {" "}
              {t("heroHome.description")}
            </p>
            <br />
            <AnchorLink
              href={"#contact-form"}
              className={"a-button a-button--arrow"}
            >
              {" "}
              {t("heroHome.cta")}
            </AnchorLink>
          </section>
        </div>
        <div className="flex-center p-bottom">
          <div className={"m-hero-detail"}>
            <h2
              className="a-subtitle a-subtitle--hero is-text-center a-underline"
              // data-sal="slide-down"
              // data-sal-delay="500"
              // data-sal-duration="800"
              // data-sal-easing="ease"
            >
              <span className={"is-orange"}> C&R Seguridad </span> -{" "}
              {t("heroHome.subtitle")}
            </h2>
            <p
              className={
                "a-paragraph a-paragraph--hero is-text-center is-translucent p-vertical"
              }
            >
              <Trans t={t}>heroHome.detail</Trans>
            </p>
          </div>
        </div>
      </div>
      <SliderServices services={services} />
      <Companies is_backLight={false} color={"light"} />
    </div>
  );
};

export default HeroHome;
